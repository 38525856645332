<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="p-4">
            <CCardBody class="login-content">
              <div class="switcher-wrapper">
                <language-switcher></language-switcher>
              </div>

              <form @submit="changePassword">
                <h1>{{ $t("login.changePassword") }}</h1>
                <p class="text-muted">
                  {{ $t("login.changePasswordDescription") }}
                </p>
                <CInput
                  v-if="!hasEmail"
                  :placeholder="$t('login.email')"
                  v-model="form.email"
                  :isValid="!$v.form.email.$invalid"
                  :tooltipFeedback="false"
                >
                  <template #prepend-content>
                    <CIcon name="cil-at" />
                  </template>
                </CInput>
                <CInput
                  :placeholder="$t('login.currentPassword')"
                  type="password"
                  v-model="form.currentPassword"
                  :isValid="!$v.form.currentPassword.$invalid"
                  :tooltipFeedback="false"
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked" />
                  </template>
                </CInput>
                <CInput
                  :placeholder="$t('login.newPassword')"
                  type="password"
                  v-model="form.newPassword"
                  :isValid="!$v.form.newPassword.$invalid"
                  :tooltipFeedback="false"
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked" />
                  </template>
                </CInput>
                <CInput
                  :placeholder="$t('login.passwordConfirmation')"
                  type="password"
                  v-model="form.passwordConfirmation"
                  :isValid="!$v.form.passwordConfirmation.$invalid"
                  :tooltipFeedback="false"
                >
                  <template #prepend-content>
                    <CIcon name="cil-lock-locked" />
                  </template>
                </CInput>

                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton
                      color="primary"
                      :disabled="!isFormValid"
                      class="px-4"
                      type="submit"
                      >{{ $t("common.continue") }}</CButton
                    >
                  </CCol>
                </CRow>
              </form>
            </CCardBody>

            <div v-if="loading" class="loading-overlay">
              <CSpinner color="primary" style="width: 3rem; height: 3rem" />
            </div>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { email, required, requiredIf, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import utilMessages from "../../utils/messages";
import LanguageSwitcher from "../hauberk/components/LanguageSwitcher.vue";

export default {
  name: "ChangeDefaultPassword",
  components: {
    LanguageSwitcher,
  },
  props: ["userLogin"],
  validations: {
    form: {
      email: {
        required: requiredIf(function () {
          return !this.hasEmail;
        }),
        email
      },
      currentPassword: {
        required,
      },
      newPassword: {
        required,
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs("newPassword"),
      },
    },
  },
  data() {
    return {
      form: {
        email: "",
        currentPassword: "",
        newPassword: "",
        passwordConfirmation: "",
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions(["logout", "changeDefaultPassword"]),
    async changePassword(event) {
      try {
        event.preventDefault();
        event.stopPropagation();

        this.loading = true;
        await this.changeDefaultPassword({
          ...this.form,
          login: this.userLogin,
        });
        await this.logout();
        this.$router.push("/login");
      } catch (error) {
        this.$toast.error(utilMessages.errMessage(error));
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    isFormValid() {
      return !this.$v.form.$invalid;
    },
    hasEmail() {
      return (
        this.$route.query.hasEmail === true ||
        this.$route.query.hasEmail === "true"
      );
    },
  },
};
</script>

<style>
.login-content {
  position: relative;
}

.switcher-wrapper {
  position: absolute;
  right: 10px;
  top: 0;
}

.my-modal-content {
  height: 450px;
  overflow-y: scroll;
}

.loading-overlay {
  position: absolute;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #00000020;
  z-index: 9999;
}
</style>
